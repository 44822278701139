<template>
  <b-row>
    <b-col cols="12">
      <account-form type='edit'/>
    </b-col>
  </b-row>
</template>

<script>
import accountForm from "./Form";
import { BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,

    accountForm,
  },
};
</script>
